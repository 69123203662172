// site theme modes [light, dark];
// Edit/Change your site themes and coloring from this file:
import { createTheme } from "@mui/material/styles";
import { red, green, deepPurple, cyan } from "@mui/material/colors";

const defaultTheme = createTheme();
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // LIGHT MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#606060`,
          },
          secondary: {
            main: deepPurple[700],
            dark: deepPurple[800],
            light: deepPurple[600],
          },
          error: {
            main: `#FF6262`,
            dark: `#FF6262`,
            light: `#FF6262`,
          },

          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `rgba(0,0,0, 0.3)`,
          },
          text: {
            primary: "#2d2d2d",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#f8f8f8",
            paper: "#ffffff",
          },
          custom: defaultTheme.palette.augmentColor({
            color: {
              main: deepPurple[600],
              dark: deepPurple[700],
              light: deepPurple[500],
            },
            name: "custom",
          }),
        }
      : {
          // LIGHT MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#606060`,
          },
          secondary: {
            main: deepPurple[700],
            dark: deepPurple[800],
            light: deepPurple[600],
          },
          error: {
            main: `#FF6262`,
            dark: `#FF6262`,
            light: `#FF6262`,
          },

          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `#f4f4f4`,
          },
          text: {
            primary: "#0c0627",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#f8f8f8",
            paper: "#ffffff",
          },
          custom: defaultTheme.palette.augmentColor({
            color: {
              main: deepPurple[600],
              dark: deepPurple[700],
              light: deepPurple[500],
            },
            name: "custom",
          }),
        }),
  },
});

export default getDesignTokens;
