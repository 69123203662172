/*!
Data Created On: Sat, May 17, 2023 [8:47 PM]
=========================================================
*  - v1.0.0
=========================================================
* Coded by:  Abraham Mitiku - abrilojson@gmail.com

=========================================================

*
*/
// -----------------------------------------------------------------
/**
 *  Importing View Components [Pages] , Layouts && Routes
 */

import React, { Suspense, lazy } from "react";

/**
 *  spinners or backdrop
 */
import MainBackdrop from "../components/layouts/spinners/Backdrop";

/**
 * Public Views
 * !Public view pages should be imported here and add with public view categories
 */

const Login = lazy(() => import("../components/views/auth/Login"));
const Forgot = lazy(() => import("../components/views/auth/Forgot"));
const Reset = lazy(() => import("../components/views/auth/Reset"));
const Notify = lazy(() => import("../components/views/auth/Notify"));
const Verify = lazy(() => import("../components/views/auth/Verify"));

//-----------------

/**
 * 


 */
// not found pages
const NotFound = lazy(() => import("../components/views/404/NotFound"));
//
const Article = lazy(() =>
  import("../components/views/private/article/Article")
);
const NewArticle = lazy(() =>
  import("../components/views/private/article/NewArticle")
);

const UpdateArticle = lazy(() =>
  import("../components/views/private/article/UpdateArticle")
);
const PreviewArticle = lazy(() =>
  import("../components/views/private/article/Preview")
);

/**
 * Private Views
 * Authorized pages [views] should be imported here and add with private view categories
 */
//
//const Notification = lazy(() =>
//import("../components/views/private/notification/Notification")
//);

/**
 *  Layouts [Private, Public]
 *  Contains - [Outlet, Navigations ... etc]
 */

const PublicLayout = lazy(() => import("../components/layouts/PublicLayout"));
const PrivateLayout = lazy(() => import("../components/layouts/PrivateLayout"));

/**
 *  Authorized Routes [PrivateRoute]
 *  Checks whether personel is authorized or not ... or will redirect to login page ...
 *  You can change authorization methods for pages [views] here from [PrivateRoute]
 */
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const PublicRoute = lazy(() => import("./PublicRoute"));

const routes = [
  /**
   *  Auth Views
   *  Directories:
   *  Views -> Auth -> [Login, Register
   */
  {
    path: "/",
    element: (
      <Suspense fallback={<MainBackdrop />}>
        <PublicLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "/login",
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "/forgot-password",
        element: (
          <PublicRoute>
            <Forgot />
          </PublicRoute>
        ),
      },
      {
        path: "/reset-password",
        element: (
          <PublicRoute>
            <Reset />
          </PublicRoute>
        ),
      },
      {
        path: "/notification/:id",
        element: (
          <PublicRoute>
            <Notify />
          </PublicRoute>
        ),
      },
      {
        path: "/verify-email/:id/:token",
        element: (
          <PublicRoute>
            <Verify />
          </PublicRoute>
        ),
      },
    ],
  },
  /**
   *
   */
  {
    path: "*",
    element: (
      <PublicRoute>
        <NotFound />
      </PublicRoute>
    ),
  },
  /**
   *  Private Views
   *  Directories:
   *  Views -> Private -> [Dashboard, Profile, ... etc]
   */
  {
    path: "/admin",
    element: (
      <Suspense fallback={<MainBackdrop />}>
        <PrivateLayout />
      </Suspense>
    ),
    children: [
      /**
       *
       */
      {
        index: true,
        element: (
          <PrivateRoute>
            <Article />
          </PrivateRoute>
        ),
      },
      {
        path: `/admin/articles`,
        element: (
          <PrivateRoute>
            <Article />
          </PrivateRoute>
        ),
      },
      {
        path: `/admin/articles/new`,
        element: (
          <PrivateRoute>
            <NewArticle />
          </PrivateRoute>
        ),
      },
      {
        path: `/admin/articles/update/:id`,
        element: (
          <PrivateRoute>
            <UpdateArticle />
          </PrivateRoute>
        ),
      },
      {
        path: `/admin/articles/preview/:id`,
        element: (
          <PrivateRoute>
            <PreviewArticle />
          </PrivateRoute>
        ),
      },
    ],
  },
];
export default routes;
