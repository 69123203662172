import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/siteStyle.css";
//import { Provider } from "react-redux";
//import store from "./redux/store/index";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-image-picker-editor/dist/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthAdminContextProvider } from "./contexts/AuthAdminContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Provider store={store}>
  <AuthAdminContextProvider>
    <Router>
      <App />
    </Router>
  </AuthAdminContextProvider>
  // </Provider>
);

serviceWorkerRegistration.unregister();
